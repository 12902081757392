<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong> Financial Reports</strong></span>
        <vue-json-to-csv :json-data="csvListData" :labels="csvLabels" :csv-title="fileName">
          <v-btn color="primary"> Export </v-btn>
        </vue-json-to-csv>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
       <v-col cols="12" md="3">
         <v-autocomplete 
              v-model="filter.center_name"
              :items="centerName" 
              item-text="center_name"
              outlined dense hide-details
              label="Center Name">
          </v-autocomplete>
      </v-col>
       <v-col cols="12" md="3">
         <v-autocomplete 
              v-model="filter.city"
              :items="roleList" 
              item-text="name"
              outlined dense hide-details
              label="City">
          </v-autocomplete>
      </v-col>
       <v-col cols="12" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filter.date_from"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.date_from"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_from" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
       <v-col cols="12" md="3">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="filter.date_to"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.date_to"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_to" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" class="mr-8" @click="reportview()">Refresh</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" class="mr-8" @click="reportSearch()"> Search</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="FinancialReports"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
    >
      <template #[`item.operate`]="{}">
        <v-btn color="primary" class="mr-3" @click="aa()"> Print </v-btn>
        <!-- <v-btn color="error" text @click="vv()"> Delete </v-btn> -->
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CenterName from '@/service/Branches.service'
import CitySrvice from '@/service/EyeDonationCenter.service'
import financialService from '@/service/Reports.service'
import VueJsonToCsv from 'vue-json-to-csv'
import { api } from '@/config/config'
import moment from 'moment'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
   components: {
    VueJsonToCsv,
  },
  data() {
    return {
        centerName:[{center_name:""}],
        roleList: [{ city_id: "", name: ""}],
      aa: '',
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      FinancialReports: [],
      filter: {
        date_from: '',
        date_to: '',
        center_name: '',
        city:'',
       
      },
       csvLabels: {
        center_name: { title: 'Cernter Name' },
        collection: { title: 'Collection'},

      },
      fileName: 'USER-LIST-' + moment().valueOf(),
       csvListData: [],

      headers: [
        { text: 'Cernter Name', value: 'center_name', width: '250px' },
        { text: 'Collection', value: 'collection', width: '250px' },
        
        // { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        // { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
        // { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.reportview()
        this.centernam()
        this.GetCity()
      },
      deep: true,
    },
  },

  async mounted() {
    this.reportview()
    this.centernam()
    this.GetCity()
  },

  methods: {
    async centernam(){
      const servce = new CenterName()
      let response = await servce.BranchesList()
      let center = response.data
      const centerName = center.map((d) => (d["center_name"]))
      // console.log("hello center", centerName)
       if (centerName) {
        this.centerName = response.data
      } else {
        this.centerName = []
      }

    },
    async GetCity() {
      const service = new CitySrvice()
      let response = await service.CityList()
      if (response) {
        this.roleList = response.data
        
      } else {
        this.roleList = []
      }
    },

    async reportview() {
      this.listLoading = true
      const service = new financialService()
      let response = await service.FinancialReports()
      if (response) {
        this.FinancialReports = response.stats
        this.csvListData = response.stats
        this.totallist = this.FinancialReports.length;
          (this.filter.date_from = ''),
          (this.filter.date_to = ''),
          (this.filter.center_name = '')
          (this.filter.city = '')
         
      } else {
        this.FinancialReports = []
        this.totallist = 0
      }
    },

    async reportSearch() {
      this.listLoading = true
      const data = {
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
        center_name: this.filter.center_name,
        city:this.filter.city
      }
      try {
        const response = await api.post(`reports/financial_report_searching`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.FinancialReports = response.data.data
          this.totallist = this.FinancialReports.length
        } else {
          this.FinancialReports = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
